import React from 'react'

import { WebLink } from '@news-mono/web-common'
import {
    StyledEditionImage,
    StyledEditionItem,
    StyledItemDescription,
    StyledItemDetail,
} from './EditionItem.styled'
import { format } from 'date-fns'
import { StyledNightlyEditionListButtonContainer } from '../../SpecificEdition/NightlySpecificEdition.styled'
import { NightlyButton } from '../../../../buttons/NightlyButton/NightlyButton'
import { EditionConfig } from '../PreviousSpecialEditions'

type SpecialEditionItemProps = Omit<
    EditionConfig,
    'heading' | 'socialImageURL' | 'featureToggle' | 'curation'
>

export const SpecialEditionItem: React.FC<SpecialEditionItemProps> = ({
    title,
    teaser,
    link,
    imageURL,
    lazyloadImages,
    publicationDate,
    path,
}) => {
    return (
        <StyledEditionItem>
            {imageURL && (
                <WebLink to={link}>
                    <StyledEditionImage
                        src={imageURL}
                        alt={title}
                        loading={lazyloadImages ? 'lazy' : undefined}
                    />
                </WebLink>
            )}

            <StyledItemDetail>
                <time dateTime={publicationDate}>
                    {format(new Date(publicationDate), 'd MMM yyyy')}
                </time>
                <StyledItemDescription>{teaser}</StyledItemDescription>
                <StyledNightlyEditionListButtonContainer>
                    <NightlyButton
                        variant={'default'}
                        text={'Read Now'}
                        action={{
                            type: 'link',
                            to: link,
                        }}
                        color={'primary'}
                        fill={'filled'}
                        layout={{
                            shouldGrow: true,
                            alignSelf: 'stretch',
                        }}
                    />
                    <NightlyButton
                        variant={'default'}
                        text={'More Articles'}
                        action={{
                            type: 'link',
                            to: path,
                        }}
                        color={'primary'}
                        fill={'outline'}
                        layout={{
                            shouldGrow: true,
                            alignSelf: 'stretch',
                        }}
                    />
                </StyledNightlyEditionListButtonContainer>
            </StyledItemDetail>
        </StyledEditionItem>
    )
}
