import React from 'react'
import { SpecialEditionProps } from '../SpecialEdition/SpecialEdition'
import {
    StyledEditionsPage,
    StyledEditionsSection,
    StyledEditionsHeading,
    StyledOnEditionList,
} from './PreviousSpecialEditions.styled'
import { SpecialEditionItem } from './components/EditionItem'

export type EditionConfig = {
    title: string
    heading: string
    teaser: string
    link: string
    imageURL: string
    socialImageURL: string
    lazyloadImages: boolean
    featureToggle: string
    alt: string
    publicationDate: string
    curation: string
    path: string
} // this is from /news-mono/apps/thenightly/src/app/routes/thenightly-on/onEditionsConfig.ts , but not importing as this config will be removed in the future

export interface NightlyPreviousSpecialEditionsProps {
    title?: string
    editions: EditionConfig[]
}

export const NightlyPreviousSpecialEditions: React.FC<NightlyPreviousSpecialEditionsProps> =
    ({ editions, title = 'Latest Nightly On Editions' }) => {
        return (
            <StyledEditionsPage>
                <StyledEditionsSection>
                    <StyledEditionsHeading>
                        {editions.length > 0 && <h2>{title}</h2>}
                    </StyledEditionsHeading>

                    <StyledOnEditionList>
                        {editions.map((edition, idx) => (
                            <SpecialEditionItem
                                title={edition.title}
                                teaser={edition.teaser}
                                link={edition.link}
                                imageURL={edition.imageURL}
                                lazyloadImages={edition.lazyloadImages}
                                key={idx}
                                alt={edition.alt}
                                publicationDate={edition.publicationDate}
                                path={edition.path}
                            />
                        ))}
                    </StyledOnEditionList>
                </StyledEditionsSection>
            </StyledEditionsPage>
        )
    }
