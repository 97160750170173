import { TheNightlySection } from '@news-mono/common'
import { calcRem, metrics } from '@news-mono/component-library'
import {
    Features,
    getElectionFeatureValue,
    isElectionFeatureEnabled,
    StaticRoutes,
} from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../App.routing'
import {
    billboardLeaderboardMrec,
    getHomepageMidCollectionAds,
    getTopicAdComponents,
} from '../ad-units'
import { appDownloadSegment } from './app-download-segment'
import {
    latestEditionSegment,
    previousEditionsSegment,
} from './get-editions-segment'
import { cardList } from './get-cardList-segment'
import { topicSection } from './get-topic-segment'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { olympicsSection } from './get-olympics-segment'
import { toWords } from 'number-to-words'
import { breakingNewsSegment } from './breaking-news-segment'
import { trendingArticlesSegment } from './get-trending-articles-segment'
import { federalElection2025Segment } from './federal-election-2025-segment'
import { getUnloadedElectionDefinition } from '../elections/routes'
import {
    latestOnEdition,
    onEditionsConfig,
} from '../thenightly-on/onEditionsConfig'

export const homepage: StaticRoutes<TheNightlySection> = {
    '': ({ getAdTargeting, renderTarget, store }) => {
        const pageMetaDescription =
            'Get in front of local, national and world news and current affairs with the best of opinion, politics, business, entertainment and sport.'

        /**
         * This is a short term solution to allow for the 'On' Edition to be swapped out between 'Leadership' and 'Tomorrow'
         *  @todo This should be replaced with a more robust solution in the future
         * */
        let specialEditionProps = {
            ...onEditionsConfig['on/leadership'],
        }

        const toggles = toFeatureState(store.getState().toggles)
        const isEnabled = (feature: Features) =>
            isFeatureEnabled(toggles, feature)

        const isTomorrowEdition = isEnabled('the-nightly-tomorrow-home-segment')
        const isLeadershipEdition = isEnabled(
            'the-nightly-leadership-home-segment',
        )

        if (isTomorrowEdition) {
            specialEditionProps = {
                ...onEditionsConfig['on/tomorrow'],
            }
        }

        const isOnEditionEnabled = isLeadershipEdition || isTomorrowEdition

        const inApp = renderTarget === 'app'
        const willDisplayOddAdsDesktop = isEnabled('tn-homepage-odd-topic-ads')
        const raiseHomepageAdSlot = isEnabled('tn-raise-homepage-adslot')
        const olympicsEnabled = isEnabled('paris-olympics-homepage-collection')
        const usPoliticsEnabled = isEnabled('us-politics-homepage-collection')

        const wanElectionId =
            toggles['homepage-election-wan-election-id']?.value.string() ||
            'federal-election-2025'
        const electionDefinition = getUnloadedElectionDefinition(wanElectionId)

        const isLatestNightlyOnEnabled = isEnabled(
            latestOnEdition.featureToggle as Features,
        )

        // --- Federal Election Toggle Checks ---
        const federalElection2025Enabled = isElectionFeatureEnabled(
            toggles,
            electionDefinition.electionId,
            'election-homepage-collection',
        )
        const electionCollectionPosition = getElectionFeatureValue(
            toggles,
            electionDefinition.electionId,
            'election-homepage-collection-position',
        )

        const adComponent = raiseHomepageAdSlot
            ? [
                  layout.component({
                      type: 'ad-unit',
                      props: {
                          adType: 'inline',
                          noticePosition: 'above-center',
                          slot: {
                              id: 'mrec-one-2',
                              size: 'leaderboardBillboard1280Above',
                          },
                          padding: [64, 0, 0, 0],
                          lazyLoadingDistance: 0,
                      },
                  }),
              ]
            : getTopicAdComponents(
                  true,
                  'mrec-one-3',
                  'one',
                  64,
                  0,
                  'above-center',
                  0,
              )

        // Used to dynamically update ad slot position
        let adSlotPos = 1

        // Used to dynamically change the aside ad show state
        let showAsideAdState: 'hide' | 'show' = 'hide'
        const getAndSetAsideShow = (): boolean => {
            // Get current state
            const innerAside = showAsideAdState
            // Update state
            showAsideAdState = innerAside === 'show' ? 'hide' : 'show'
            // Return boolean on inner state (old state)
            return innerAside === 'show' ? true : willDisplayOddAdsDesktop
        }

        return {
            kind: 'page',
            heading: 'The Nightly',
            pageType: 'homepage',
            hideHeading: true,
            refreshOnInactive: true,
            noMetaTitleTemplate: true,
            pageMeta: {
                title: 'The Nightly | Get in front of local, national and world news and current affairs with the best of opinion, politics, business, entertainment and sport',
                description: pageMetaDescription,
                meta: [
                    {
                        property: 'og:description',
                        content: pageMetaDescription,
                    },
                    {
                        property: 'twitter:description',
                        content: pageMetaDescription,
                    },
                ],
            },
            adTargeting: getAdTargeting('home', 'default', 'home'),
            section: 'default',
            additionalPageProperties: {
                hasTopGutter: false,
            },
            compositions: [
                breakingNewsSegment(),
                layout.composition({
                    type: 'box',
                    props: {
                        flex: {
                            default: {
                                flexDirection: 'column',
                                gap: calcRem(40),
                            },
                            sm: {},
                        },
                    },
                    contentAreas: {
                        main: [
                            ...(federalElection2025Enabled &&
                            electionCollectionPosition === 'high'
                                ? [
                                      ...federalElection2025Segment(
                                          electionDefinition,
                                          getAdTargeting,
                                      ),
                                  ]
                                : []),
                            layout.nestedComposition({
                                type: 'luna',
                                props: {
                                    //Create account CTA 'replaces' these dividers when not in app rendition.
                                    dividerConfig: {
                                        column: {
                                            hasBeforeAsideAndAsideDivider:
                                                inApp,
                                        },
                                        split: {
                                            hasBeforeAsideAndAfterAsideDivider:
                                                inApp,
                                        },
                                    },
                                    hasMarginTop: true,
                                },
                                contentAreas: {
                                    beforeAside: [
                                        toggleCreateAccountCallout({
                                            shouldHide: inApp,
                                            content: layout.component({
                                                type: 'card',
                                                props: {
                                                    kind: 'lead-card',
                                                    dataDefinitionArgs: {
                                                        type: 'curation',
                                                        name: 'top-stories',
                                                        offset: 0,
                                                        pageSize: 1,
                                                    },
                                                    adTargeting: getAdTargeting(
                                                        'home',
                                                        'default',
                                                        'home',
                                                    ),
                                                    imageFetchPriority: 'high',
                                                    disableImageLazyLoad: true,
                                                },
                                            }),
                                        }),
                                    ],
                                    afterAside: [
                                        layout.component({
                                            type: 'ad-unit',
                                            props: {
                                                adType: 'inline',
                                                noticePosition: 'above-center',
                                                slot: {
                                                    id: 'mrec-one-1',
                                                    size: 'billboardLeaderboard1280BelowMrec768Below',
                                                },
                                                padding: [0, 0, 64, 0],
                                                lazyLoadingDistance: 0,
                                            },
                                            feature: 'tn-raise-homepage-adslot',
                                        }),
                                        latestEditionSegment,
                                        ...(isOnEditionEnabled
                                            ? [
                                                  layout.component({
                                                      type: 'nightly-special-edition-home',
                                                      props: {
                                                          title: specialEditionProps.title,
                                                          teaser: specialEditionProps.teaser,
                                                          link: specialEditionProps.link,
                                                          imageURL:
                                                              specialEditionProps.imageURL,
                                                          lazyloadImages: true,
                                                      },
                                                  }),
                                              ]
                                            : []),
                                    ],
                                    aside: [
                                        layout.nestedComposition({
                                            type: 'box',
                                            props: {
                                                horizontalSpacing:
                                                    'outerMargin',
                                                horizontalSpacingBreakpoint:
                                                    'sm',
                                            },
                                            contentAreas: {
                                                main: [
                                                    cardList(
                                                        {
                                                            type: 'curation',
                                                            name: 'top-stories',
                                                            offset: 1,
                                                            pageSize: 5,
                                                        },
                                                        'The Top 5',
                                                    ),
                                                ],
                                            },
                                        }),
                                        layout.nestedComposition({
                                            type: 'box',
                                            props: {
                                                stickyOffset:
                                                    'calc(var(--stickyHeaderHeight) + 80px)',
                                                hasBackgroundFill: true,
                                            },
                                            contentAreas: {
                                                main: [
                                                    layout.component({
                                                        type: 'ad-unit',
                                                        props: {
                                                            adType: 'inline',
                                                            noticePosition:
                                                                'above-center',
                                                            slot: {
                                                                id: 'type-lead-story',
                                                                size: 'above1280MrecHalfPage',
                                                                lazyLoadingDistance: 0,
                                                            },
                                                        },
                                                    }),
                                                ],
                                            },
                                        }),
                                    ],
                                },
                            }),
                            mainContentSection([
                                layout.component({
                                    type: 'tn-newsletter-signup',
                                    props: {
                                        text: `Sign up to The Nightly's newsletters.`,
                                        subText:
                                            'Get the first look at the digital newspaper, curated daily stories and breaking headlines delivered to your inbox.',
                                        buttonLabel: 'Get the Newsletter',
                                        mode: 'default',
                                    },
                                }),
                                ...adComponent,
                            ]),
                            trendingArticlesSegment(),
                            ...(federalElection2025Enabled &&
                            electionCollectionPosition === 'low'
                                ? [
                                      ...federalElection2025Segment(
                                          electionDefinition,
                                          getAdTargeting,
                                      ),
                                  ]
                                : []),
                            ...(usPoliticsEnabled
                                ? [
                                      topicSection({
                                          topic: 'politics/us-politics',
                                          curation: 'us-politics',
                                          section: 'us-politics',
                                          adPos: adSlotPos++,
                                          adTargeting: getAdTargeting(
                                              'home',
                                              'default',
                                              'home',
                                          ),
                                          showAsideAdvert: getAndSetAsideShow(),
                                          feature:
                                              'us-politics-homepage-collection',
                                          debuggingCustomId: 'us-politics-list',
                                          isLatestNightlyOnEnabled:
                                              isLatestNightlyOnEnabled,
                                      }),
                                      getHomepageMidCollectionAds(
                                          isDisplayDesktop(
                                              adSlotPos,
                                              willDisplayOddAdsDesktop,
                                          ),
                                          adSlotPos,
                                      ),
                                  ]
                                : []),
                            topicSection({
                                section: 'politics',
                                adPos: adSlotPos++,
                                adTargeting: getAdTargeting(
                                    'home',
                                    'default',
                                    'home',
                                ),
                                showAsideAdvert: getAndSetAsideShow(),
                                debuggingCustomId: 'politics-list',
                                isLatestNightlyOnEnabled:
                                    isLatestNightlyOnEnabled,
                            }),
                            ...(olympicsEnabled
                                ? [
                                      olympicsSection({
                                          adPos: adSlotPos++,
                                          adTargeting: getAdTargeting(
                                              'home',
                                              'default',
                                              'home',
                                          ),
                                          showAsideAdvert: getAndSetAsideShow(),
                                          debuggingCustomId: 'olympics-list',
                                          feature:
                                              'paris-olympics-homepage-collection',
                                      }),
                                  ]
                                : []),
                            getHomepageMidCollectionAds(
                                isDisplayDesktop(
                                    adSlotPos,
                                    willDisplayOddAdsDesktop,
                                ),
                                adSlotPos,
                            ),
                            ...(inApp
                                ? []
                                : [
                                      mainContentSection(
                                          [appDownloadSegment],
                                          'web-app-download',
                                          true,
                                      ),
                                  ]),
                            topicSection({
                                section: 'australia',
                                adPos: adSlotPos++,
                                adTargeting: getAdTargeting(
                                    'home',
                                    'default',
                                    'home',
                                ),
                                showAsideAdvert: getAndSetAsideShow(),
                                debuggingCustomId: 'australia-list',
                                isLatestNightlyOnEnabled:
                                    isLatestNightlyOnEnabled,
                            }),
                            getHomepageMidCollectionAds(
                                isDisplayDesktop(
                                    adSlotPos,
                                    willDisplayOddAdsDesktop,
                                ),
                                adSlotPos,
                            ),
                            topicSection({
                                section: 'business',
                                adPos: adSlotPos++,
                                adTargeting: getAdTargeting(
                                    'home',
                                    'default',
                                    'home',
                                ),
                                showAsideAdvert: getAndSetAsideShow(),
                                debuggingCustomId: 'business-list',
                                isLatestNightlyOnEnabled:
                                    isLatestNightlyOnEnabled,
                            }),
                            getHomepageMidCollectionAds(
                                isDisplayDesktop(
                                    adSlotPos,
                                    willDisplayOddAdsDesktop,
                                ),
                                adSlotPos,
                            ),
                            topicSection({
                                section: 'opinion',
                                adPos: adSlotPos++,
                                adTargeting: getAdTargeting(
                                    'home',
                                    'default',
                                    'home',
                                ),
                                showAsideAdvert: getAndSetAsideShow(),
                                debuggingCustomId: 'opinion-list',
                                isLatestNightlyOnEnabled:
                                    isLatestNightlyOnEnabled,
                            }),
                            getHomepageMidCollectionAds(
                                isDisplayDesktop(
                                    adSlotPos,
                                    willDisplayOddAdsDesktop,
                                ),
                                adSlotPos,
                            ),
                            topicSection({
                                topic: 'from/the-new-york-times',
                                curation: 'new-york-times',
                                section: 'from',
                                adPos: adSlotPos++,
                                adTargeting: getAdTargeting(
                                    'home',
                                    'default',
                                    'home',
                                ),
                                showAsideAdvert: getAndSetAsideShow(),
                                debuggingCustomId: 'NYT-list',
                                isLatestNightlyOnEnabled:
                                    isLatestNightlyOnEnabled,
                            }),
                            getHomepageMidCollectionAds(
                                isDisplayDesktop(
                                    adSlotPos,
                                    willDisplayOddAdsDesktop,
                                ),
                                adSlotPos,
                            ),
                            topicSection({
                                section: 'culture',
                                adPos: adSlotPos++,
                                adTargeting: getAdTargeting(
                                    'home',
                                    'default',
                                    'home',
                                ),
                                showAsideAdvert: getAndSetAsideShow(),
                                debuggingCustomId: 'culture-list',
                                isLatestNightlyOnEnabled:
                                    isLatestNightlyOnEnabled,
                            }),
                            getHomepageMidCollectionAds(
                                isDisplayDesktop(
                                    adSlotPos,
                                    willDisplayOddAdsDesktop,
                                ),
                                adSlotPos,
                            ),
                            topicSection({
                                section: 'society',
                                adPos: adSlotPos++,
                                adTargeting: getAdTargeting(
                                    'home',
                                    'default',
                                    'home',
                                ),
                                showAsideAdvert: getAndSetAsideShow(),
                                debuggingCustomId: 'society-list',
                                isLatestNightlyOnEnabled:
                                    isLatestNightlyOnEnabled,
                            }),
                            getHomepageMidCollectionAds(
                                isDisplayDesktop(
                                    adSlotPos,
                                    willDisplayOddAdsDesktop,
                                ),
                                adSlotPos,
                            ),
                            topicSection({
                                section: 'lifestyle',
                                adPos: adSlotPos++,
                                adTargeting: getAdTargeting(
                                    'home',
                                    'default',
                                    'home',
                                ),
                                showAsideAdvert: getAndSetAsideShow(),
                                debuggingCustomId: 'lifestyle-list',
                                isLatestNightlyOnEnabled:
                                    isLatestNightlyOnEnabled,
                            }),
                            getHomepageMidCollectionAds(
                                isDisplayDesktop(
                                    adSlotPos,
                                    willDisplayOddAdsDesktop,
                                ),
                                adSlotPos,
                            ),
                            topicSection({
                                section: 'sport',
                                adPos: adSlotPos++,
                                adTargeting: getAdTargeting(
                                    'home',
                                    'default',
                                    'home',
                                ),
                                showAsideAdvert: getAndSetAsideShow(),
                                debuggingCustomId: 'sport-list',
                                isLatestNightlyOnEnabled:
                                    isLatestNightlyOnEnabled,
                            }),
                            getHomepageMidCollectionAds(
                                isDisplayDesktop(
                                    adSlotPos,
                                    willDisplayOddAdsDesktop,
                                ),
                                adSlotPos,
                            ),
                            topicSection({
                                section: 'world',
                                adPos: adSlotPos++,
                                adTargeting: getAdTargeting(
                                    'home',
                                    'default',
                                    'home',
                                ),
                                showAsideAdvert: getAndSetAsideShow(),
                                debuggingCustomId: 'world-list',
                                isLatestNightlyOnEnabled:
                                    isLatestNightlyOnEnabled,
                            }),
                            getHomepageMidCollectionAds(
                                isDisplayDesktop(
                                    adSlotPos,
                                    willDisplayOddAdsDesktop,
                                ),
                                adSlotPos,
                            ),
                            topicSection({
                                topic: 'culture/best-australian-yarn',
                                curation: 'best-australian-yarn',
                                section: 'best-australian-yarn',
                                adPos: adSlotPos++,
                                adTargeting: getAdTargeting(
                                    'home',
                                    'default',
                                    'home',
                                ),
                                showAsideAdvert: getAndSetAsideShow(),
                                feature:
                                    'best-australian-yarn-curation-segment',
                                debuggingCustomId: 'best-yarn-list',
                                isLatestNightlyOnEnabled:
                                    isLatestNightlyOnEnabled,
                            }),
                            layout.nestedComposition({
                                type: 'mars',
                                props: {
                                    sectionHeader: 'In case you missed it',
                                },
                                contentAreas: {
                                    primary: [
                                        layout.component({
                                            type: 'card',
                                            props: {
                                                kind: 'list-card',
                                                dataDefinitionArgs: {
                                                    type: 'curation',
                                                    name: 'in-case-you-missed-it',
                                                    offset: 0,
                                                    pageSize: 1,
                                                },
                                                canPlayVideoInline: true,
                                            },
                                        }),
                                    ],
                                    secondary: [
                                        layout.component({
                                            type: 'juliet',
                                            props: {
                                                cardType: {
                                                    type: 'landscape',
                                                    format: 'landscape-common',
                                                },
                                                imageWidths: {
                                                    mobile: '80px',
                                                    tablet: '104px',
                                                    desktop: '128px',
                                                    fallbackWidth: 128,
                                                    mode: 'fixed',
                                                },
                                                verticalSpacing: 'md',
                                                removeHorizontalGutters: true,
                                                removeTopListPadding: true,
                                                hideByline: false,
                                                topicLevel: 'parent',
                                                hideLastDivider: true,
                                                dataDefinitionArgs: {
                                                    type: 'curation',
                                                    name: 'in-case-you-missed-it',
                                                    offset: 1,
                                                    pageSize: 4,
                                                },
                                            },
                                        }),
                                    ],
                                },
                            }),
                            ...(inApp
                                ? []
                                : [
                                      layout.nestedComposition({
                                          type: 'thor',
                                          props: {
                                              hasBackgroundFill: false,
                                              containerWidth:
                                                  metrics.thenightly.siteMetrics
                                                      .mainContentWidth,
                                              horizontalGutters: false,
                                              verticalGutters: false,
                                          },
                                          contentAreas: {
                                              main: [previousEditionsSegment],
                                          },
                                      }),
                                  ]),
                            layout.nestedComposition({
                                type: 'thor',
                                props: {
                                    hasBackgroundFill: false,
                                    containerWidth:
                                        metrics.thenightly.siteMetrics
                                            .mainContentWidth,
                                    horizontalGutters: 'outerMargin',
                                    verticalGutters: false,
                                },
                                contentAreas: {
                                    main: billboardLeaderboardMrec(
                                        `mrec-${toWords(adSlotPos)}`,
                                        toWords(adSlotPos++),
                                        0,
                                        'above-center',
                                        0,
                                    ),
                                },
                            }),
                        ],
                    },
                }),
            ],
        }
    },
}

function mainContentSection(
    components: Array<CheckedComponentInformation>,
    feature?: Features,
    hasBackgroundFill?: boolean,
) {
    return layout.nestedComposition({
        type: 'thor',
        feature: feature,
        props: {
            hasBackgroundFill: false,
            containerWidth: '100%',
            horizontalGutters: false,
            verticalGutters: false,
        },
        contentAreas: {
            main: [
                layout.nestedComposition({
                    type: 'thor',
                    props: {
                        hasBackgroundFill: hasBackgroundFill,
                        containerWidth: '100%',
                        verticalGutters: false,
                        horizontalGutters: false,
                    },
                    contentAreas: {
                        main: components,
                    },
                }),
            ],
        },
    })
}

const isDisplayDesktop = (
    adSlotPos: number,
    willDisplayOddAdsDesktop: boolean,
): boolean => {
    // if the position is odd return the value of the willDisplayOddAdsDesktop feature flag, otherwise return true
    return adSlotPos % 2 !== 0 ? willDisplayOddAdsDesktop : true
}

const toggleCreateAccountCallout = ({
    shouldHide,
    content,
}: {
    shouldHide: boolean
    content: CheckedComponentInformation
}) => {
    if (shouldHide) return content

    return layout.nestedComposition({
        type: 'box',
        props: {
            flex: {
                default: {
                    flexDirection: 'column',
                    gap: calcRem(40),
                },
                sm: {
                    gap: calcRem(64),
                },
                lg: {
                    gap: calcRem(80),
                },
            },
        },
        contentAreas: {
            main: [
                content,
                layout.component({
                    type: 'thenightly-create-account-cta',
                    props: {},
                }),
            ],
        },
    })
}
