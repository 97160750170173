import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    AppState,
    AuthenticationState,
    DataLayerEventName,
    authHasSignedUpNewsletterState,
    NewsletterSignupEvent,
} from '@news-mono/web-common'
import { useRecaptcha } from '../../../recaptcha'
import { handleUnknownError } from '@news-mono/common'
import { get as getCookie, set as setCookie } from 'js-cookie'
import {
    OPTED_IN_NEWSLETTER_COOKIE_NAME,
    SIGNED_UP_TO_NEWSLETTER_COOKIE_NAME,
    newsletterSubscribe,
    updateUserMetadata,
} from '../helpers/newsletter-subscription'

export interface TNAuthenticatedNewsletterSignupProps {
    onEvent: (event: NewsletterSignupEvent) => void
}

export const TNAuthenticatedNewsletterSignup = ({
    onEvent,
}: TNAuthenticatedNewsletterSignupProps) => {
    const {
        isLoggedIn,
        emailVerified,
        isLoading,
        hasSignedupToNewsletter,
        hasOptedInNewsletter,
        wanUserId,
        auth0UserId,
        userName,
        userEmail,
    } = useSelector<AppState, AuthenticationState>(
        (state) => state.authentication,
    )

    const [isSignedUpToNews, setIsSignedUpToNews] = useState<boolean>(false)
    const [isOptedInNewsletter, setIsOptedInNewsletter] =
        useState<boolean>(false)
    const [isSigningUpToNewsletter, setIsSigningUpToNewsletter] =
        useState<boolean>(false)

    const dispatch = useDispatch()

    // Fallback to stop spamming when accessToken has not refreshed but a request has been tried to subscribe
    // After 1 day will check the AccessToken for updated userInfo or on token refresh
    useEffect(() => {
        setIsSignedUpToNews(
            getCookie(SIGNED_UP_TO_NEWSLETTER_COOKIE_NAME) === 'true'
                ? true
                : false,
        )

        setIsOptedInNewsletter(
            getCookie(OPTED_IN_NEWSLETTER_COOKIE_NAME) === 'true'
                ? true
                : false,
        )
    }, [])

    const { executeRecaptcha } = useRecaptcha()

    const signupTheNightlyNews = async () => {
        try {
            // set the steate to prevent multiple calls
            setIsSigningUpToNewsletter(true)

            // retreive the token
            const token = await executeRecaptcha({ action: 'subscribe' })

            // subscribe using the default opt in list
            const response = await newsletterSubscribe(
                token,
                userEmail,
                userName,
            )

            if (response.ok) {
                // fire success event
                onEvent({
                    type: DataLayerEventName.newsletterSignupSuccess,
                    originator: 'TNAuthenticatedNewsletterSignup',
                    payload: {
                        newsletterVariant: 'post-registration',
                    },
                })

                // update the user metadata
                updateUserMetadata(
                    auth0UserId || wanUserId,
                    isOptedInNewsletter || hasOptedInNewsletter,
                    true,
                )

                setCookie(SIGNED_UP_TO_NEWSLETTER_COOKIE_NAME, 'true', {
                    expires: 1, //days
                })

                // set auth state and update the cookie
                dispatch(authHasSignedUpNewsletterState(true))
                return
            } else {
                // fire failure event
                onEvent({
                    type: DataLayerEventName.newsletterSignupFail,
                    originator: 'TNAuthenticatedNewsletterSignup',
                    payload: {
                        newsletterVariant: 'post-registration',
                    },
                })

                throw new Error(
                    `Auto NewsletterSignup submission error: ${response.status} ${response.statusText}`,
                )
            }
        } catch (error) {
            const err = handleUnknownError(error)
            console.error(err)
            // Fallback to stop spamming when failed send. Will force a retry in 24hrs
            setCookie(SIGNED_UP_TO_NEWSLETTER_COOKIE_NAME, 'true', {
                expires: 1,
            })
            return
        } finally {
            setIsSignedUpToNews(true)
            setIsSigningUpToNewsletter(false)
        }
    }

    const signedUpToNewsletter =
        hasSignedupToNewsletter === true || isSignedUpToNews
    const optedInNewsletter =
        hasOptedInNewsletter === true || isOptedInNewsletter

    if (signedUpToNewsletter) {
        return <></>
    }

    if (
        !isLoading &&
        !isSigningUpToNewsletter &&
        isLoggedIn &&
        emailVerified &&
        optedInNewsletter &&
        !signedUpToNewsletter
    ) {
        signupTheNightlyNews()

        return <></>
    }

    return <></>
}
